<script lang="ts" setup>
import { ref, watch } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'

import { useRoute, useRouter } from 'vue-router/composables'
import { api } from '@/api'
import { useToastStore } from '@/stores'

const { $toast } = useToastStore()
const infiniteRef = ref<HTMLElement>()
const route = useRoute()
const router = useRouter()

const status = ref(route.query.status ?? '1')

const list = ref<any>([])
// onMounted(async () => {
//   const status = tab.value === 'all' ? '' : tab.value === 'serving' ? '3' : '4'
//   const { data } = await api.get(`/escort/serv_order/list?status=${status}`)
//   list.value = data.items
// })

const page = ref(1)
const ending = ref(false)
const fetching = ref(false)
const error = ref(null)

function onChangeTab(value: string) {
  if (value === status.value)
    return
  status.value = value
  router.replace({ query: { status: value } })
}

// 新增的方法
function safeNavigate(itemId: number, itemStatus: number) {
  if (itemStatus !== 1)
    router.push(`/ticket/${itemId}?type=2`)
}
async function takeOrder(itemId, itemStatus) {
  const { code } = await api.post(`/escort/askOrderEscort/update?id=${itemId}&itemStatus=${itemStatus}`)
  if (code === 200) {
    page.value = 1 // 重置页码
    list.value = [] // 清空列表
    ending.value = false // 重置结束标志
    error.value = null // 清除错误
    await fetchTickets() // 重新获取数据
    $toast('操作成功！')
  }
}
async function fetchTickets() {
  fetching.value = true
  try {
    const { data } = await api.get(`/escort/askOrderEscort/index?status=${status.value}&page=${page.value}`)
    list.value = list.value.concat(data.items)
    page.value = page.value + 1
    if (data.pageInfo.currentPage === data.pageInfo.totalPage)
      ending.value = true
  }
  catch (err: any) {
    error.value = err
  }
  finally {
    fetching.value = false
  }
}

useInfiniteScroll(infiniteRef,
  async () => {
    if (fetching.value || ending.value || error.value)
      return
    await fetchTickets()
  },
  { distance: 50 },
)

watch(() => route.fullPath, () => {
  list.value = []
  page.value = 1
  ending.value = false
  fetching.value = false
  error.value = null
  fetchTickets()
})
</script>

<template>
  <div ref="infiniteRef" class="h-screen pb-20 overflow-y-scroll bg-white">
    <BasicTitle title="服务单" />

    <div class="w-full h-10 flex items-center fixed left-0 bg-white">
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '1' }"
        @click="onChangeTab('1')"
      >
        待接单
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '2' }"
        @click="onChangeTab('2')"
      >
        服务中
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '3' }"
        @click="onChangeTab('3')"
      >
        已完成
      </p>
      <p
          class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '5' }"
          @click="onChangeTab('5')"
      >
        已取消
      </p>
      <p
        class="flex-1 text-center lh-10" :class="{ 'text-blue font-bold': status === '4' }"
        @click="onChangeTab('4')"
      >
        全部
      </p>
    </div>

    <div class="mt-14 px-8">
      <div
        v-for="item in list" :key="item.id"
        class="block py-2 border rounded mb-4"
        @click="item.status !== 3 ? safeNavigate(item.order_id, item.status) : null"
      >
        <div class="px-4 flex justify-between">
          <div>
            <p>{{ item.data.hospital }}</p>
            <p class="text-sm text-#999">
              全程陪诊
            </p>
          </div>
          <p v-if="1 === item.status" class="text-red">
            待接单
          </p>
          <p v-else-if="2 === item.status" class="text-blue">
            待联系
          </p>
          <p v-else-if="3 === item.status" class="text-blue">
            已拒单
          </p>
          <p v-else-if="4 === item.status" class="text-blue">
            已联系
          </p>
          <p v-else-if="5 === item.status" class="text-blue">
            陪诊中
          </p>
          <p v-else-if="6 === item.status" class="text-blue">
            待反馈
          </p>
          <p v-else-if="7 === item.status" class="text-blue">
            陪诊完成
          </p>
          <p v-else-if="8 === item.status" class="text-blue">
            已取消
          </p>
        </div>
        <div class="px-4 mt-2 pt-2 border-t">
          <p>负责人: {{ item.leader_name }}</p>
          <p>就诊时间: {{ item.data.visit_date }}</p>
          <p>医生: {{ item.data.doctor }}</p>
          <p>科室: {{ item.data.keshi }}</p>
          <p>就诊人: {{ item.data.visit_name }}</p>
          <p>医院地址: {{ item.data.hospital_address }}</p>
        </div>
        <div v-if="status === '1'" class="flex justify-center mt-4">
          <button class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-10 rounded mr-4" @click="() => takeOrder(item.id, 3)">
            拒单
          </button>
          <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-10 rounded" @click="() => takeOrder(item.id, 2)">
            接单
          </button>
        </div>
      </div>
    </div>

    <div v-if="ending" class="py-15px text-13px text-#999 text-center">
      我们是有底线的~
    </div>
    <div v-else-if="fetching" class="py-15px flex items-center justify-center gap-4 text-13px text-#999">
      <div class="animate-spin">
        <i-icon-park-outline-loading />
      </div>
      <p>加载中...</p>
    </div>

    <BasicTabbar />
  </div>
</template>
