<script lang="ts" setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { $formatErrorMessage } from '@/helpers'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()
const router = useRouter()
const selectedTimes = reactive({})
const buttonStyles = reactive({})
const show_dates = reactive({})
const currentDate = ref(new Date())
const timeSlots = computed(() => [
  { label: '上午', value: 'morning', remark: '12:00以前' },
  { label: '下午', value: 'afternoon', remark: '12:00-16:30' },
  { label: '晚间', value: 'evening', remark: '16:30以后' },
])

const dates = computed(() => {
  const dates = []
  for (let i = 0; i <= 7; i++) {
    const date = new Date(currentDate.value)
    date.setDate(date.getDate() + i)
    const dateString = `${date.getFullYear().toString().padStart(4, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
    const showDateString = `${date.getDate().toString().padStart(2, '0')}`
    show_dates[showDateString] = dateString

    // 确保selectedTimes对象中有这个日期的条目，并且它是一个对象
    if (!selectedTimes[showDateString])
      selectedTimes[showDateString] = {}

    dates.push({
      date: showDateString,
      dayOfWeek: getDayOfWeek(date),
      // 注意这里selectedTimes是一个对象，我们通过方括号来访问
      selectedTimes: selectedTimes[showDateString],
    })
  }
  return dates
})

function getDayOfWeek(date: Date): string {
  const days = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  return days[date.getDay()]
}
function isToday(dateStr) {
  const today = new Date()
  const todayStr = `${today.getDate().toString().padStart(2, '0')}`

  return dateStr === todayStr
}
function isSelected(date, timeSlot) {
  return !!selectedTimes[date]?.[timeSlot]
}
function toggleSelection(date, timeSlot) {
  // 如果该日期尚未存在于selectedTimes中，创建一个新的对象
  if (!selectedTimes[date])
    selectedTimes[date] = {}

  const dateSelectedTimes = selectedTimes[date]

  // 切换时间槽的选择状态
  if (dateSelectedTimes[timeSlot])
    delete dateSelectedTimes[timeSlot]
  else
    dateSelectedTimes[timeSlot] = true

  // 更新按钮样式，如果需要的话
  const key = `${date}-${timeSlot}`
  buttonStyles[key] = isSelected(date, timeSlot) ? 'selected' : ''
  updateButtonClass(date, timeSlot)
}
function updateButtonClass(date, timeSlot) {
  const selector = `[data-date="${date}"][data-time-slot="${timeSlot}"]`
  const button = document.querySelector(selector)
  if (button)
    button.classList.toggle('selected', selectedTimes[date]?.[timeSlot])
}
onMounted(async () => {
  try {
    $showLoading()
    const response = await api.get('/escort/user/getMyTime')
    const savedTimes = response.data

    savedTimes.forEach(({ date, times }) => {
      if (date === undefined || !Array.isArray(times)) {
        return;
      }
      const shortDate = date.slice(8, 10)
      // 确保selectedTimes中存在这个日期的条目
      if (!selectedTimes[shortDate])
        selectedTimes[shortDate] = {}

      times.forEach((time) => {
        // 直接设置时间槽为已选中状态
        selectedTimes[shortDate][time] = true

        // 更新按钮类名，如果需要的话
        updateButtonClass(shortDate, time)
      })
    })
    $hideLoading()
  }
  catch (error) {
    $toast($formatErrorMessage(error))
    $hideLoading()
  }
})
async function onSave() {
  try {
    $showLoading()
    // 假设后端API接收的格式为 { dates: [{date: '2023-01-01', times: ['morning', 'afternoon']}, ...] }
    const payload = Object.entries(selectedTimes).map(([date, times]) => ({
      date: show_dates[date], // 使用show_dates映射转换日期格式
      times: Object.keys(times).filter(key => times[key]), // 获取所有选中的时间槽
    }))
    await api.post('/escort/user/setMyTime', { myTime: payload })
    $toast('接单时间设置成功')
    router.back()
  }
  catch (error: any) {
    console.error(error)
    $toast(error.message)
  }
  finally {
    $hideLoading()
  }
}
</script>

<template>
  <div class="min-h-screen px-4" style="background-color: white;">
    <BasicTitle title="设置接单时间" />
    <p style="margin-top: 0.5em">
      温馨提示：仅可设置7天内的接单时间
      <button class="save-button" @click="onSave">
        保存
      </button>
    </p>
    <div class="grid-container">
      <!-- 时间列标题 -->
      <div class="time-slot-header">
        <div v-for="timeSlot in timeSlots" :key="timeSlot.value" class="header-item">
          {{ timeSlot.label }}
          <p>({{ timeSlot.remark }})</p>
        </div>
      </div>

      <!-- 日期与时间按钮 -->
      <template v-for="(date, index) in dates">
        <div :key="`date-${index}-${date.date}`" class="date-item" :grid-area="`date-area-${index}`">
          <p style="font-weight: bold;font-size: 1.2em;">
            {{ date.date }}
          </p>
          <p>{{ date.dayOfWeek }}</p>
        </div>
        <div v-for="timeSlot in timeSlots" :key="`${index}-${timeSlot.value}`" class="time-slot">
          <button
            :data-date="date.date"
            :data-time-slot="timeSlot.value"
            class="w-full h-10 rounded-full"
            :disabled="isToday(date.date)"
            @click="() => toggleSelection(date.date, timeSlot.value)"
          >
            接单
          </button>
        </div>
        <div v-if="index !== dates.length - 1" class="divider" />
      </template>
    </div>
  </div>
</template>

<style>
.grid-container {
  display: grid;
  gap: 0.1rem; /* 行列间距 */
  margin-top: 1em;
}
.time-slot-header {
  grid-column: 2 / span 3;
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: space-between;
}
.header-item {
  margin-right: 0.5em;
  flex: 1;
  font-size: 1.4em;
  color: #5793df;
}
.header-item p {
  font-size: 0.3em;
}
.time-slot {
  width: 80%;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如果需要的话 */
  gap: 0.5rem;
}
.time-slot button {
  color: rgb(200,200,200);
  border-radius: 0%;
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  position: relative;
  background-color: white !important;
  border: 1px solid rgb(200,200,200);
  overflow: visible; /* 确保伪元素可以超出按钮边界 */
}
/* 定义一个选中状态的类 */
.time-slot button.selected {
  color: #5793df;
  background-color: white;
  border: 2px solid #5793df;
}
.time-slot button::before {
  content: ""; /* 用于创建三角形的伪元素 */
  position: absolute;
  right: 0; /* 调整三角形与按钮边缘的距离 */
  bottom: 0; /* 调整三角形与按钮底部的距离 */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 25px 22px; /* 修改为下、左、上、右 */
  border-color: transparent transparent rgb(200,200,200) transparent; /* 下、左、上、右 */
}
.time-slot button::after {
  content: '\2713'; /* Unicode for check mark */
  font-size: 0.8em;
  position: absolute;
  right: 0; /* 调整对勾与三角形右侧的距离 */
  bottom: 0; /* 调整对勾与三角形底部的距离 */
  color: white; /* 设置对勾的颜色 */
}
.time-slot button.selected::before {
  content: ""; /* 用于创建三角形的伪元素 */
  position: absolute;
  right: 0; /* 调整三角形与按钮边缘的距离 */
  bottom: 0; /* 调整三角形与按钮底部的距离 */
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 25px 22px; /* 修改为下、左、上、右 */
  border-color: transparent transparent #5793df transparent; /* 下、左、上、右 */
}
.time-slot button.selected::after {
  content: '\2713'; /* Unicode for check mark */
  font-size: 0.8em;
  position: absolute;
  right: 0;
  bottom: 0;
}

.date-item {
  width: 80%;
  color: #5793df;
  display: flex;
  flex-direction: column; /* 设置为列方向 */
  align-items: center; /* 垂直居中 */
}
.save-button {
  z-index: 99;
  background-color: #5793df;
  color: white; /* 白色文字 */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19); /* 阴影 */
  position: fixed;
  bottom: 4em;
  right: 1em;
  width: 4em;
  height: 4em;
  border: 2px solid;
  border-radius: 3em;
}
/* 分界线 */
.divider {
  display: block;
  width: 100%;
  grid-column: 1 / 5; /* 跨越所有列 */
  height: 1px;
  background-color: #ccc;
  margin: 10px 0;
}
.first_background{
  background-color: #e0fbfd;
}
</style>
