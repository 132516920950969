<script lang="ts" setup>
import { onMounted, ref, set } from 'vue'

import { useRouter } from 'vue-router/composables'
import Compressor from 'compressorjs'

// import { van-datetime-picker } from 'vant'
import axios from 'axios'
import { api } from '@/api'
import { useLoadingStore, useToastStore } from '@/stores'
import { $formatDate, $formatErrorMessage, $getCurrentDate } from '@/helpers'
import { getExtension } from '@/utils'

const props = defineProps<{
  id: number | string
}>()

const { $showLoading, $hideLoading } = useLoadingStore()
const { $toast } = useToastStore()

type Tab = 'info' | 'data'

const tab = ref<Tab>('info')

function onChangeTab(value: Tab) {
  tab.value = value
}

const arrival_time_picker = ref(false)
const registration_time_picker = ref(false)
const entry_time_picker = ref(false)
const exit_time_picker = ref(false)
const pickup_time_picker = ref(false)
const handle_time_picker = ref(false)
const appointment_time_picker = ref(false)
const end_time_picker = ref(false)
const proof_images = ref<any>([])
const file_images = ref<any>([])
const phone_proof_images = ref<any>([])
const visit_register_img = ref<any>([])
const sms_proof_images = ref<any>([])
const reach_proof_images = ref<any>([])
const register_slip_images = ref<any>([])
const hospital_proof_images = ref<any>([])
const material_proof_images = ref<any>([])
const satisfaction_survey_images = ref<any>([])
const ticket = ref<any>()
const use_router = useRouter()
onMounted(async () => {
  try {
    $showLoading()
    const { data } = await api.get(`/escort/serv_order/detail/${props.id}?type=${use_router.currentRoute.query.type}`)
    ticket.value = {
      ...data,
      escort_return_data: {
        ...(data.escort_return_data && {
          ...data.escort_return_data,
          all_registration_fee: String(data.escort_return_data?.all_registration_fee || ''),
          self_pay_registration_fee: String(data.escort_return_data?.self_pay_registration_fee || ''),
          escort_fee_gather_status: String(data.escort_return_data.escort_fee_gather_status) || '1',
        }),
      },
    }
    ticket.value.data.is_register_fee = ticket.value.data.is_register_fee === '1'?'是':'否'
    if (data && data.escort_return_data && data.escort_return_data.phone_proof_images && Array.isArray(data.escort_return_data.phone_proof_images) && data.escort_return_data.phone_proof_images.length > 0)
      phone_proof_images.value = data.escort_return_data.phone_proof_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.sms_proof_images && Array.isArray(data.escort_return_data.sms_proof_images) && data.escort_return_data.sms_proof_images.length > 0)
      sms_proof_images.value = data.escort_return_data.sms_proof_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.reach_proof_images && Array.isArray(data.escort_return_data.reach_proof_images) && data.escort_return_data.reach_proof_images.length > 0)
      reach_proof_images.value = data.escort_return_data.reach_proof_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.register_slip_images && Array.isArray(data.escort_return_data.register_slip_images) && data.escort_return_data.register_slip_images.length > 0)
      register_slip_images.value = data.escort_return_data.register_slip_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.hospital_proof_images && Array.isArray(data.escort_return_data.hospital_proof_images) && data.escort_return_data.hospital_proof_images.length > 0)
      hospital_proof_images.value = data.escort_return_data.hospital_proof_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.material_proof_images && Array.isArray(data.escort_return_data.material_proof_images) && data.escort_return_data.material_proof_images.length > 0)
      material_proof_images.value = data.escort_return_data.material_proof_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.satisfaction_survey_images && Array.isArray(data.escort_return_data.satisfaction_survey_images) && data.escort_return_data.satisfaction_survey_images.length > 0)
      satisfaction_survey_images.value = data.escort_return_data.satisfaction_survey_images.map((item: string) => ({ url: item }))
    if (data && data.escort_return_data && data.escort_return_data.file_images && Array.isArray(data.escort_return_data.file_images) && data.escort_return_data.file_images.length > 0)
      file_images.value = data.escort_return_data.file_images.map((item: string) => ({ url: item }))
    if (data && data.visit_register_img && Array.isArray(data.visit_register_img))
      visit_register_img.value = data.visit_register_img.map((item: string) => ({ url: item }))
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
})
const router = useRouter()

function gotoBack() {
  router.back()
}

const minDate = new Date()
minDate.setDate(minDate.getDate() - 3)
function onClockIn(name: string) {
  if (!ticket.value.escort_return_data[name])
    set(ticket.value.escort_return_data, name, $getCurrentDate())
  onSave()
}
function uploadBeforeRead(file: File) {
  if (Array.isArray(file)) {
    const promises = file.map((item) => {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        // eslint-disable-next-line no-new
        new Compressor(item, {
          success: resolve,
          error(err: any) {
            console.error(err.message)
          },
        })
      })
    })

    return Promise.all(promises)
  }
  //   return Promise.all(file.map(item => uploadBeforeRead(item)))

  return new Promise((resolve) => {
    // compressorjs 默认开启 checkOrientation 选项
    // 会将图片修正为正确方向
    // eslint-disable-next-line no-new
    new Compressor(file, {
      success: resolve,
      error(err: any) {
        console.error(err.message)
      },
    })
  })
}

async function uploadAfterRead(file: { file: File; [key: string]: any }) {
  const files = Array.isArray(file) ? file : [file]
  for (const file of files) {
    // /escort/serv_order/get_upload_params
    file.status = 'uploading'
    file.message = '上传中...'
    // Get Signature from Server
    const { data: signature } = await api.get(`/escort/serv_order/get_upload_params?ext=${getExtension(file.file.name)}`)
    // axios.post()
    try {
      const { status } = await axios.post(signature.host,
        {
          key: signature.key,
          policy: signature.policy,
          OSSAccessKeyId: signature.accessKeyId,
          signature: signature.signature,
          file: file.file,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )

      if (status !== 204) {
        file.status = 'failed'
        file.message = '上传失败'
        return
      }

      file.status = 'done'
      file.url = signature.url
    }
    catch (error: any) {
      console.error(error)
      file.status = 'failed'
      file.message = '上传失败'
    }
  }
}

async function onContact() {
  try {
    if (!phone_proof_images.value || !phone_proof_images.value.length || !sms_proof_images.value || !sms_proof_images.value.length) {
      $toast('请上传证明！')
      return false
    }
    $showLoading()
    await api.post('/escort/askOrderEscort/contact', {
      serv_order_id: props.id,
      phone_proof_images: phone_proof_images.value.map((item: any) => item.url),
      sms_proof_images: sms_proof_images.value.map((item: any) => item.url),
    })
    $toast('服务单完成成功')
    gotoBack()
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}

async function onFinish() {
  try {
    $showLoading()
    await api.post('/escort/serv_order/finish', {
      type: 2,
      serv_order_id: props.id,
      ...ticket.value.escort_return_data,
      ...formatDate(ticket.value.escort_return_data),
      proof_images: proof_images.value.map((item: any) => item.url),
      file_images: file_images.value.map((item: any) => item.url),
      phone_proof_images: phone_proof_images.value.map((item: any) => item.url),
      sms_proof_images: sms_proof_images.value.map((item: any) => item.url),
      register_slip_images: register_slip_images.value.map((item: any) => item.url),
      reach_proof_images: reach_proof_images.value.map((item: any) => item.url),
      hospital_proof_images: hospital_proof_images.value.map((item: any) => item.url),
      material_proof_images: material_proof_images.value.map((item: any) => item.url),
      satisfaction_survey_images: satisfaction_survey_images.value.map((item: any) => item.url),
    })
    $toast('服务单完成成功')
    gotoBack()
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}

async function onSave() {
  try {
    $showLoading()
    await api.post(`/escort/serv_order/update?type=${use_router.currentRoute.query.type}`, {
      serv_order_id: props.id,
      ...ticket.value.escort_return_data,
      ...formatDate(ticket.value.escort_return_data),
      proof_images: proof_images.value.map((item: any) => item.url),
      file_images: file_images.value.map((item: any) => item.url),
      phone_proof_images: phone_proof_images.value.map((item: any) => item.url),
      sms_proof_images: sms_proof_images.value.map((item: any) => item.url),
      register_slip_images: register_slip_images.value.map((item: any) => item.url),
      reach_proof_images: reach_proof_images.value.map((item: any) => item.url),
      hospital_proof_images: hospital_proof_images.value.map((item: any) => item.url),
      material_proof_images: material_proof_images.value.map((item: any) => item.url),
      satisfaction_survey_images: satisfaction_survey_images.value.map((item: any) => item.url),
    })
    $toast('暂存成功')
  }
  catch (error: any) {
    $toast($formatErrorMessage(error))
  }
  finally {
    $hideLoading()
  }
}

function formatDate(data: any) {
  return {
    arrival_time: data.arrival_time ? $formatDate(data.arrival_time) : '',
    registration_time: data.registration_time ? $formatDate(data.registration_time) : '',
    entry_time: data.entry_time ? $formatDate(data.entry_time) : '',
    exit_time: data.exit_time ? $formatDate(data.exit_time) : '',
    pickup_time: data.pickup_time ? $formatDate(data.pickup_time) : '',
    appointment_time: data.appointment_time ? $formatDate(data.appointment_time) : '',
    handle_time: data.handle_time ? $formatDate(data.handle_time) : '',
    end_time: data.end_time ? $formatDate(data.end_time) : '',
  }
}
</script>

<template>
  <div v-if="!ticket" />
  <div v-else class="min-h-screen bg-white">
    <BasicTitle title="服务单" />

    <div class="w-full h-10 flex items-center fixed left-0 bg-white">
      <p
        class="mytab flex-1 text-center lh-10" :class="{ 'text-blue font-bold': tab === 'info' }"
        @click="onChangeTab('info')"
      >
        预约信息
      </p>
      <p
        class="mytab flex-1 text-center lh-10" :class="{ 'text-blue font-bold': tab === 'data' }"
        @click="onChangeTab('data')"
      >
        数据上传
      </p>
    </div>

    <!-- Info -->
    <div v-if="tab === 'info'" class="mt-14 px-8">
      <h1>服务单状态：<span class="text-blue">{{ ticket.escort_status_name }}</span></h1>
      <div class="py-2 flex">
        <p>挂号信息：</p>
        <van-uploader
          v-model="visit_register_img" class="showUpload" disabled multiple :before-read="uploadBeforeRead"
          :after-read="uploadAfterRead"
        />
      </div>
      <h1>挂号费陪诊收取：<span class="text-blue">{{ ticket.data.is_register_fee }}（金额：{{ ticket.data.escort_fee }}元）</span></h1>
      <div class="py-2 border rounded shadow-lg">
        <div class="px-4">
          <p class="font-bold">
            {{ ticket.hospital }}
          </p>
          <p class="font-bold">
            到院时间：{{ ticket.arrival_time }}
          </p>
          <p>
            全程陪诊
          </p>
        </div>
        <div class="mt-2 pt-2 px-4 border-t">
          <div class="flex justify-between">
            <p>医生:</p>
            <p>{{ ticket.doctor }}</p>
          </div>
          <div class="flex justify-between">
            <p>科室:</p>
            <p>{{ ticket.department }}</p>
          </div>
          <div class="flex justify-between">
            <p>客服备注:</p>
            <p>{{ ticket.remark }}</p>
          </div>
          <div class="flex justify-between">
            <p>病情主诉:</p>
            <p>{{ ticket.disease_summary }}</p>
          </div>
          <div class="flex justify-between">
            <p>用药信息:</p>
            <p>{{ ticket.medication }}</p>
          </div>
        </div>
      </div>

      <p class="mt-8 mb-4 text-lg font-bold">
        就诊人
      </p>

      <div class="py-2 border rounded shadow-lg">
        <div class="px-4 flex gap-2 font-bold">
          <span>{{ ticket.patient.name }}</span>
          <span>{{ ['未知', '男', '女'][ticket.patient.gender] }}</span>
          <template v-if="ticket.patient.age">
            <span>|</span>
            <span>{{ ticket.patient.age }} 岁</span>
          </template>
        </div>
        <div class="mt-2 pt-2 px-4 border-t">
          <a class="block flex justify-between" :href="`tel:${ticket.patient.phone}`">
            <p>联系电话</p>
            <div class="flex items-center gap-2">
              <p>{{ ticket.patient.phone }}</p>
              <i-icon-park-outline-phone-call class="text-blue" />
            </div>
          </a>
        </div>
      </div>

      <!--  -->
      <div class="mt-10 flex gap-4 justify-between">
        <a
          class="block w-full h-10 lh-10 text-white text-center bg-green rounded-full"
          href="tel:010-60646093"
        >
          联系客服
        </a>
        <button
          class="w-full h-10 text-white bg-blue rounded-full"
          @click="gotoBack()"
        >
          返回首页
        </button>
      </div>
    </div>
    <!-- Data -->
    <div v-else class="mt-14">
      <h1 class="mx-4 py-2 flex-col">
        服务单状态：<span class="text-blue">{{ ticket.escort_status_name }}</span>
      </h1>
      <div class="mx-4 py-2 flex">
        <p>挂号信息：</p>
        <van-uploader
          v-model="visit_register_img" class="showUpload" disabled multiple :before-read="uploadBeforeRead"
          :after-read="uploadAfterRead"
        />
      </div>
      <h1 class="mx-4 py-2 flex-col">
        挂号费陪诊收取：<span class="text-blue">{{ ticket.data.is_register_fee }}（金额：{{ ticket.data.escort_fee }}元）</span>
      </h1>
      <div v-if="ticket.escort_status === 2">
        <div class="mx-4 py-2 flex flex-col">
          <p>联系客户证明（电话联系凭证）:</p>
          <p class="my-2">
            图片上传
          </p>
          <van-uploader
            v-model="phone_proof_images" multiple :before-read="uploadBeforeRead"
            :after-read="uploadAfterRead"
          />
        </div>
        <div class="mx-4 py-2 flex flex-col">
          <p>联系客户证明（短信联系凭证）:</p>
          <p class="my-2">
            图片上传
          </p>
          <van-uploader
            v-model="sms_proof_images" multiple :before-read="uploadBeforeRead"
            :after-read="uploadAfterRead"
          />
        </div>
        <div class="py-10 mx-4 flex gap-4 justify-between">
          <button
            class="w-full h-10 text-white bg-green rounded-full"
            @click="onContact"
          >
            联系完成
          </button>
        </div>
      </div>
      <div v-else>
        <div>
          <p class="mx-4 text-#999">
            就诊信息
          </p>
          <div class="mx-4 py-2 flex">
            <p>就诊医院:</p>
            <input
              v-model="ticket.escort_return_data.hospital"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-#999" placeholder="请输入就诊医院"
            >
          </div>
          <div class="mx-4 py-2 flex">
            <p>就诊科室:</p>
            <input
              v-model="ticket.escort_return_data.keshi"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-#999" placeholder="请输入就诊科室"
            >
          </div>
          <div class="mx-4 py-2 flex">
            <p>医生姓名:</p>
            <input
              v-model="ticket.escort_return_data.doctor_name"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-#999" placeholder="请输入医生姓名"
            >
          </div>
          <div class="mx-4 py-2 flex">
            <p>患者病情:</p>
            <textarea
              v-model="ticket.escort_return_data.disease"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-#999" placeholder="请输入患者病情"
            />
          </div>
          <div class="mx-4 py-2 flex">
            <p>检查项目:</p>
            <textarea
              v-model="ticket.escort_return_data.check_name"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-#999" placeholder="请输入检查项目"
            />
          </div>
          <p class="mx-4 text-#999">
            时间打卡
          </p>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              到院时间:
            </p>
            <span
              v-if="ticket.escort_return_data.arrival_time" class="flex-1 text-center"
              @click="arrival_time_picker = true"
            >{{ $formatDate(ticket.escort_return_data.arrival_time) }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="arrival_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('arrival_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="arrival_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.arrival_time"
              class="z-11" title="到院时间"
              :min-date="minDate"
              @confirm="arrival_time_picker = false" @cancel="arrival_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              取号时间:
            </p>
            <span
              v-if="ticket.escort_return_data.registration_time" class="flex-1 text-center"
              @click="registration_time_picker = true"
            >{{
              $formatDate(ticket.escort_return_data.registration_time)
            }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="registration_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('registration_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="registration_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.registration_time"
              class="z-11" title="取号时间"
              :min-date="minDate"
              @confirm="registration_time_picker = false" @cancel="registration_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              进诊室时间:
            </p>
            <span
              v-if="ticket.escort_return_data.entry_time" class="flex-1 text-center"
              @click="entry_time_picker = true"
            >{{ $formatDate(ticket.escort_return_data.entry_time) }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="entry_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('entry_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="entry_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.entry_time"
              class="z-11" title="进诊室时间"
              :min-date="minDate"
              @confirm="entry_time_picker = false" @cancel="entry_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              出诊室时间:
            </p>
            <span
              v-if="ticket.escort_return_data.exit_time" class="flex-1 text-center"
              @click="exit_time_picker = true"
            >{{ $formatDate(ticket.escort_return_data.exit_time) }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="exit_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('exit_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="exit_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.exit_time"
              class="z-11" title="出诊室时间"
              :min-date="minDate"
              @confirm="exit_time_picker = false" @cancel="exit_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              取药时间:
            </p>
            <span
              v-if="ticket.escort_return_data.pickup_time" class="flex-1 text-center"
              @click="pickup_time_picker = true"
            >{{ $formatDate(ticket.escort_return_data.pickup_time) }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="pickup_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('pickup_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="pickup_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.pickup_time"
              class="z-11" title="取药时间"
              :min-date="minDate"
              @confirm="pickup_time_picker = false" @cancel="pickup_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              办理检查时间:
            </p>
            <span
              v-if="ticket.escort_return_data.handle_time" class="flex-1 text-center"
              @click="handle_time_picker = true"
            >{{ $formatDate(ticket.escort_return_data.handle_time) }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="handle_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('handle_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="handle_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.handle_time"
              class="z-11" title="办理检查时间"
              :min-date="minDate"
              @confirm="handle_time_picker = false" @cancel="handle_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              检查预约时间:
            </p>
            <span
              v-if="ticket.escort_return_data.appointment_time" class="flex-1 text-center"
              @click="appointment_time_picker = true"
            >{{
              $formatDate(ticket.escort_return_data.appointment_time)
            }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="appointment_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('appointment_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="appointment_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.appointment_time"
              class="z-11" title="检查预约时间"
              :min-date="minDate"
              @confirm="appointment_time_picker = false" @cancel="appointment_time_picker = false"
            />
          </div>
          <div class="my-2 mx-4 flex items-center justify-between">
            <p class="w-26">
              结束时间:
            </p>
            <span v-if="ticket.escort_return_data.end_time" class="flex-1 text-center" @click="end_time_picker = true">{{
              $formatDate(ticket.escort_return_data.end_time)
            }}</span>
            <span v-else class="flex-1 text-center text-#999" @click="end_time_picker = true">请选择时间</span>
            <button
              class="px-4 py-2 text-sm whitespace-nowrap text-white bg-green rounded"
              @click="onClockIn('end_time')"
            >
              打卡
            </button>
          </div>
          <div v-if="end_time_picker" class="w-full fixed bottom-0 left-0">
            <div class="fixed inset-0 z-10 bg-#00000020" />
            <van-datetime-picker
              v-model="ticket.escort_return_data.end_time"
              class="z-11" title="结束时间"
              :min-date="minDate"
              @confirm="end_time_picker = false" @cancel="end_time_picker = false"
            />
          </div>
        </div>

        <div class="mt-2 pt-2 border-t">
          <p class="mx-4 text-#999">
            挂号费
          </p>
          <div class="mx-4 py-2 flex">
            <p>挂号费:</p>
            <input
              v-model="ticket.escort_return_data.all_registration_fee"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8" class="flex-1 text-right"
              placeholder="请输入金额"
            >
          </div>
          <div class="mx-4 py-2 flex">
            <p>挂号费收取状态:</p>
            <select
              v-model="ticket.escort_return_data.escort_fee_gather_status"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
              class="flex-1 text-right text-gray-600"
            >
              <option value="1">
                未反馈
              </option>
              <option value="2">
                客户现场支付
              </option>
              <option value="3">
                客户医保支付
              </option>
              <option value="4">
                不需陪诊收取
              </option>
              <option value="5">
                陪诊垫付挂号费
              </option>
            </select>
          </div>
        </div>

        <div class="mt-2 pt-2 border-t">
          <p class="mx-4 text-#999">
            诊断
          </p>
          <div class="mx-4 py-2 flex flex-col">
            <p>医生诊断:</p>
            <textarea
              v-model="ticket.escort_return_data.diagnosis"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8" class="flex-1"
              placeholder="医生诊断"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>用药信息:</p>
            <textarea
              v-model="ticket.escort_return_data.drug_information"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8" class="flex-1"
              placeholder="用药信息"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>医生治疗方案:</p>
            <textarea
              v-model="ticket.escort_return_data.treatment_plan"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8" class="flex-1"
              placeholder="医生治疗方案"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>备注信息:</p>
            <textarea
              v-model="ticket.escort_return_data.remark"
              :disabled="ticket.escort_status === 7 || ticket.escort_status === 8" class="flex-1"
              placeholder="备注信息"
            />
          </div>
        </div>

        <div class="mt-2 pt-2 border-t">
          <p class="mx-4 text-#999">
            文件上传
          </p>
          <div class="mx-4 py-2 flex flex-col">
            <p>电话联系凭证:</p>
            <van-uploader
              v-model="phone_proof_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>短信联系凭证:</p>
            <van-uploader
              v-model="sms_proof_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>到院凭证:</p>
            <van-uploader
              v-model="reach_proof_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>挂号条:</p>
            <van-uploader
              v-model="register_slip_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>住院证:</p>
            <van-uploader
              v-model="hospital_proof_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>物料包:</p>
            <van-uploader
              v-model="material_proof_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>满意度调查表:</p>
            <van-uploader
              v-model="satisfaction_survey_images" multiple :before-read="uploadBeforeRead"
              :after-read="uploadAfterRead"
            />
          </div>
          <div class="mx-4 py-2 flex flex-col">
            <p>相关信息材料上传:</p>
            <p class="my-2">
              图片上传
            </p>
            <van-uploader v-model="file_images" multiple :before-read="uploadBeforeRead" :after-read="uploadAfterRead" />
          </div>
        </div>

        <div class="py-10 mx-4 flex gap-4 justify-between">
          <button
            class="w-full h-10 text-white bg-green rounded-full"
            @click="onFinish"
          >
            完成服务单
          </button>
          <button
            class="w-full h-10 text-white bg-blue rounded-full"
            :disabled="ticket.escort_status === 7 || ticket.escort_status === 8"
            @click="onSave()"
          >
            暂存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.showUpload .van-uploader__upload {
  display: none;
}

.showUpload .van-uploader__preview-delete {
  display: none;
}
.mytab{
  background-color: #f2f2f2;
  border: 1px solid #bfbfbf;
}
</style>
